






















































































































































import { Component, Emit, Vue } from 'vue-property-decorator'
import utility from '@/common/utility'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import transService from '@/services/transService'
import symbolService from '@/services/symbolService'
import { StockPrice, TransactionItem } from '@/services/data'
import SymbolName from '@/components/SymbolName.vue'
import { ElForm } from 'element-ui/types/form'
import validation from '@/common/element-val'
import TransactionForm from '@/components/TransactionForm.vue'

@Component({
  components: {
    NumberRowEditor,
    SymbolName,
    TransactionForm
  }
})

export default class Transactions extends Vue {
  $refs: {
    modelForm: ElForm;
  }

  trans: TransactionItem[] = []
  symbolId: number
  symbolPrice: StockPrice = null
  editingItem: TransactionItem = null
  showTransDialog = false
  rules = {
    CostPerShare: validation.requiredNumberRule(),
    TransDate: validation.requiredRule()
  }

  async mounted () {
    this.symbolId = parseInt(this.$route.query.symbolId as string)
    await this.loadTrans()
    const symbolRes = await symbolService.getSymbolPriceAsync(this.symbolId)
    this.symbolPrice = symbolRes.Result
  }

  async loadTrans () {
    const res = await transService.getTransactions(this.symbolId)
    if (res.Result) {
      res.Result.forEach(p => {
        p.Type = p.Quantity > 0 ? 'Buy' : 'Sell'
      })
    }
    this.trans = res.Result
  }

  async delItem (item: TransactionItem) {
    const res = await transService.removeSymbolTran(item.SymbolTransId)
    if (res.Result) {
      utility.removeArrayItem(this.trans, item)
    } else {
      await this.$alert(res.Error)
    }
  }

  $tradeAmount (item: TransactionItem, exRate: number) {
    return ((item.CostPerShare * item.Quantity) / exRate)
  }

  $settlementAmount (item: TransactionItem, exRate: number) {
    const amt = this.$tradeAmount(item, exRate)
    if (!item.Commission) {
      return item.Commission
    }
    return amt - item.Commission
  }

  get totalShare () {
    let total = 0
    this.trans.forEach(p => {
      total += p.Quantity
    })
    return total
  }

  get totalCommission () {
    let total = 0
    this.trans.forEach(p => {
      if (p.Commission) {
        total += p.Commission
      }
    })
    return total
  }

  get totalSettlement () {
    if (!this.symbolPrice) {
      return null
    }
    let total = 0
    this.trans.forEach(p => {
      total += this.$settlementAmount(p, this.symbolPrice.ExRate)
    })
    return Math.round(Math.abs(total * -1))
  }

  editItem (item: TransactionItem) {
    this.editingItem = Object.assign({}, item)
  }

  cancelEdit () {
    this.editingItem = null
  }

  async saveEdit (item: TransactionItem) {
    this.$refs.modelForm.validate(async (valid: boolean) => {
      if (valid) {
        const res = await transService.addOrUpdateTransaction(this.editingItem)
        if (res.Result) {
          item.TransDate = this.editingItem.TransDate
          item.Type = this.editingItem.Type
          item.Quantity = this.editingItem.Quantity
          item.Commission = this.editingItem.Commission
          item.CostPerShare = this.editingItem.CostPerShare
          this.editingItem = null
        } else {
          await this.$alert(res.Error)
        }
      }
    })
  }

  transTypeChanged (item: TransactionItem) {
    if (item.Type === 'Sell') {
      item.Quantity = -1 * Math.abs(item.Quantity)
    } else if (item.Type === 'Buy') {
      item.Quantity = Math.abs(item.Quantity)
    }
  }

  async addTransaction (item: TransactionItem) {
    item.SymbolId = this.symbolId
    const res = await transService.addOrUpdateTransaction(item)
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      await this.loadTrans()
      this.showTransDialog = false
    }
  }
}
